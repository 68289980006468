
import { Options, Vue } from "vue-class-component";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import { IAppUser } from "@/types/AppUser";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import FooterComponent from "@/components/FooterComponent.vue";

@Options({
  name: "SearchEmail",
  components: {
    FooterComponent,
    Form,
    Field,
    ErrorMessage,
  },
  data: function () {
    return {
      loading: false,
      message: "",
      schema: yup.object().shape({
        email: yup
          .string()
          .email("Must be a valid email")
          .required("Email is required!"),
      }),
    };
  },
})
export default class UserRegistration extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  email = "";
  token = "";
  message = "";
  loading = false;
  showInput = true;

  resend() {
    this.showInput = true;
  }

  sendEmail(data: any): void {
    this.message = "";
    this.loading = true;
    this.workForceService
      .post<void>(
        "/account/forgotPassword?email=" + encodeURIComponent(data.email),
        null
      )
      .then((response: any) => {
        if (!response.isError) {
          this.loading = false;
          this.showInput = false;
        } else if (response.isSuccess == false) {
          this.message = response.errors;
          this.loading = false;
        } else {
          this.loading = false;
          this.message = response.errors;
        }
      });
  }
}
